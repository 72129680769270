// 第二層 ROUTER 
// 教育行政管理
let EducationAdministrativeMs = {
  path: '/EducationAdministrativeMs',
  redirect: '/EducationAdministrativeMs/SchoolManager',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      // 學校管理
      path: 'SchoolManager',
      name: 'SchoolManager',
      component: () => import('@/views/php/EducationAdministrativeMs/SchoolManager/PageRecord.vue'),
    },
    {
      // 學年度管理
      path: 'AcademicYearsMS',
      name: 'AcademicYearsMS',
      component: () => import('@/views/php/EducationAdministrativeMs/AcademicYearsMS/PageRecord.vue'),
    },
  ]
};
    
export default EducationAdministrativeMs;
    