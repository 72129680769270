<template>
  <div class="fixed-plugin">
    <a class="fixed-plugin-button text-dark position-fixed px-3 py-2">
      <i class="material-icons py-2">settings</i>
    </a>
    <div class="card shadow-lg">
      <div class="card-header pb-0 pt-3">
        <div class="float-start">
          <h5 class="mt-3 mb-0">
            UI Configurator
          </h5>
          <p>See our dashboard options.</p>
        </div>
        <div class="float-end mt-4">
          <button class="btn btn-link text-dark p-0 fixed-plugin-close-button">
            <i class="material-icons">clear</i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="horizontal dark my-1">
      <div class="card-body pt-sm-3 pt-0">
        <!-- Sidebar Backgrounds -->
        <div>
          <h6 class="mb-0">
            Sidebar Colors
          </h6>
        </div>
        <a
          href="javascript:void(0)"
          class="switch-trigger background-color"
        >
          <div class="badge-colors my-2 text-start">
            <span
              class="badge filter bg-gradient-primary active"
              data-color="primary"
              onclick="sidebarColor(this)"
            />
            <span
              class="badge filter bg-gradient-dark"
              data-color="dark"
              onclick="sidebarColor(this)"
            />
            <span
              class="badge filter bg-gradient-info"
              data-color="info"
              onclick="sidebarColor(this)"
            />
            <span
              class="badge filter bg-gradient-success"
              data-color="success"
              onclick="sidebarColor(this)"
            />
            <span
              class="badge filter bg-gradient-warning"
              data-color="warning"
              onclick="sidebarColor(this)"
            />
            <span
              class="badge filter bg-gradient-danger"
              data-color="danger"
              onclick="sidebarColor(this)"
            />
          </div>
        </a>
        <!-- Sidenav Type -->
        <div class="mt-3">
          <h6 class="mb-0">
            Sidenav Type
          </h6>
          <p class="text-sm">
            Choose between 2 different sidenav types.
          </p>
        </div>
        <div class="d-flex">
          <button
            class="btn bg-gradient-dark px-3 mb-2 active"
            data-class="bg-gradient-dark"
            onclick="sidebarType(this)"
          >
            Dark
          </button>
          <button
            class="btn bg-gradient-dark px-3 mb-2 ms-2"
            data-class="bg-transparent"
            onclick="sidebarType(this)"
          >
            Transparent
          </button>
          <button
            class="btn bg-gradient-dark px-3 mb-2 ms-2"
            data-class="bg-white"
            onclick="sidebarType(this)"
          >
            White
          </button>
        </div>
        <p class="text-sm d-xl-none d-block mt-2">
          You can change the sidenav type just on desktop view.
        </p>
        <!-- Navbar Fixed -->
        <div class="mt-3 d-flex">
          <h6 class="mb-0">
            Navbar Fixed
          </h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              id="navbarFixed"
              onclick="navbarFixed(this)"
            >
          </div>
        </div>
        <hr class="horizontal dark my-3">
        <div class="mt-2 d-flex">
          <h6 class="mb-0">
            Sidenav Mini
          </h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              id="navbarMinimize"
              onclick="navbarMinimize(this)"
            >
          </div>
        </div>
        <hr class="horizontal dark my-3">
        <div class="mt-2 d-flex">
          <h6 class="mb-0">
            Light / Dark
          </h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              id="dark-version"
              onclick="darkMode(this)"
            >
          </div>
        </div>
        <hr class="horizontal dark my-sm-4">
        <a
              rel="noopener"
          class="btn bg-gradient-primary w-100"
          href="https://oceanlighttech.com/"
          target="_blank"
        >OceanLight Tech</a>
        <a
              rel="noopener"
          class="
          btn
          bg-gradient-info
          w-100"
          href="https://oceanlighttech.com/"
          target="_blank"
        >Contact US</a>
        <a
              rel="noopener"
          class="
          btn
          btn-outline-dark
          w-100"
          href="https://oceanlighttech.com/"
          target="_blank"
        >Product</a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { useI18n } from 'vue-i18n';
export default {
  props: {
    /* eslint-disable vue/require-default-prop */
    showmode: String,
    tname: String,
    hiddenHeader:Boolean,
    routerBack:Boolean,
    routerName:String,
    routerTitle:String,
    rightbtnname: String,
    onright:Function,
    onleft:Function,
    rightbtnstatus: String,
    righttype: String
  },
  data () {
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';

    return { 
      //i18n
      t,
      locale,
      // params
      setDebugMode:false , // 該功能的除錯模式
      apiDebug:false , // API 除錯模式
      apiGetPageDebug:false , // API getPageRecord 除錯模式
      apiGetRtDebug:false , // API getRt 除錯模式
      apiGetOneDebug:false , // API getOneRecord 除錯模式
      apiPutDebug:false , // API 修改 除錯模式
      apiPostDebug:false , // API 新增 除錯模式
      apiDeleteDebug:false , // API 刪除 除錯模式
      platform:'client' ,    
      apiName:'user_role_priority' ,   
      apiParam:{
        uid:'',
      },
      conditionType:'pky',  
      pky:{'uid':'user_role_priority' }, 
      rtSet:{},
      record: [],
      totalPage:'',
      session: {},
      recordData: [],
      rtAllRecord:[],
      rtPageRecord:[],
      selectItems:{},
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      pageTab:0 ,
      mail_total:0,
      view_id:'',
      timer_id:'',
    };
  },
  created(){

  },
  mounted(){ 

  },
  methods:{
  },
};
</script>

