
// 第二層 ROUTER 
//訊息使用導引
let UserGuideCreate = {
  path: '/UserGuideCreate',
  redirect: '/UserGuideCreate/Dashboard',
  component: () => import('@/views/php/EditArchitecture/UserGuide/OltFrame.vue') ,
  children: [
    {
      path: 'Step6_6',
      name: 'Step6_6',
      component: () => import('@/views/php/EditArchitecture/UserGuide/Step6_6.vue'),
    },
  ]
};

export default UserGuideCreate;
