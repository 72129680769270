// 第二層 ROUTER 
// 繪圖管理
let DrawingMs = {
  path: '/DrawingMs',
  redirect: '/DrawingMs/DrawingMs',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {//繪圖管理
      path: 'DrawingMs',
      name: 'DrawingMs',
      component: () => import('@/views/php/DrawingMs/DrawingMs/PageRecord.vue'),
    },
    {//建立繪圖
      path: 'CreateDrawing',
      name: 'CreateDrawing',
      component: () => import('@/views/php/DrawingMs/CreateDrawing/PageRecord.vue'),
    },
    {//機構基本資料
      path: 'institutionInformation',
      name: 'institutionInformation',
      component: () => import('@/views/php/DrawingMs/institutionInformation/PageRecord.vue'),
    },
    {//繪圖範本
      path: 'TemplateMs',
      name: 'TemplateMs',
      component: () => import('@/views/php/DrawingMs/TemplateMs/PageRecord.vue'),
    },
    {//學校資料管理
      path: 'SchoolDataMs',
      name: 'SchoolDataMs',
      component: () => import('@/views/php/DrawingMs/SchoolDataMs/PageRecord.vue'),
    },
    {//建築物送審
      path: 'BuildingHandover',
      name: 'BuildingHandover',
      component: () => import('@/views/php/DrawingMs/BuildingHandover/PageRecord.vue'),
    },
    {//建築物管理
      path: 'DrawingBuildingMs',
      name: 'DrawingBuildingMs',
      component: () => import('@/views/php/DrawingMs/DrawingBuildingMs/PageRecord.vue'),
    },
    {//校稿學校資料
      path: 'ProofreadingSchool',
      name: 'ProofreadingSchool',
      component: () => import('@/views/php/DrawingMs/ProofreadingSchool/PageRecord.vue'),
    },
    {//子建築送審
      path: 'SubBuildingHandover',
      name: 'SubBuildingHandover',
      component: () => import('@/views/php/DrawingMs/SubBuildingHandover/PageRecord.vue'),
    },
    {//子建築審核
      path: 'SubDrawingBuildingMs',
      name: 'SubDrawingBuildingMs',
      component: () => import('@/views/php/DrawingMs/SubDrawingBuildingMs/PageRecord.vue'),
    },
    {//機構編輯
      path: 'Institution',
      name: 'Institution',
      component: () => import('@/views/php/DrawingMs/Institution/PageRecord.vue'),
    },
    {//空間大小數量統計
      path: 'RoomSummery',
      name: 'RoomSummery',
      component: () => import('@/views/php/DrawingMs/RoomSummery/PageRecord.vue'),
    }, 
    {// 範本區-詳細資料
      path: 'TemplateInformation',
      name: 'TemplateInformation',
      component: () => import('@/views/php/DrawingMs/TemplateInformation/PageRecord.vue'),
    }, 
    {// 範本區-建築物群組總覽
      path: 'TemplateBuildingOverview',
      name: 'TemplateBuildingOverview',
      component: () => import('@/views/php/DrawingMs/TemplateBuildingOverview/PageRecord.vue'),
    }, 
    {// 範本區-建築物空間資料
      path: 'SubTemplateBuildingMs',
      name: 'SubTemplateBuildingMs',
      component: () => import('@/views/php/DrawingMs/SubTemplateBuildingMs/PageRecord.vue'),
    }
    
  ]
};

export default DrawingMs;
