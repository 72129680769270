// 第二層 ROUTER 
// 系統管理
let AdminUserSelfMs = {
  path: '/AdminUserSelfMs',
  redirect: '/AdminUserSelfMs/ProfileOverview',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      // 個人資訊
      path: 'ProfileOverview',
      name: 'ProfileOverview',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileOverview/PageRecord.vue'),
    },
    {
      // 個人設定
      path: 'ProfileSettings',
      name: 'ProfileSettings',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileSettings/PageRecord.vue'),
    },
    {
      // 訊息通知
      path: 'ProfileMessages',
      name: 'ProfileMessages',
      component: () => import('@/views/php/AdminUserSelfMs/ProfileMessages/PageRecord.vue'),
    },
    {
      // 更多訊息
      path: 'AllMessages',
      name: 'AllMessages',
      component: () => import('@/views/php/AdminUserSelfMs/AllMessages/PageRecord.vue'),
    },
    {
      // 瀏覽內容
      path: 'MessageContent',
      name: 'MessageContent',
      component: () => import('@/views/php/AdminUserSelfMs/MessageContent/PageRecord.vue'),
    },
  ]
};
    
export default AdminUserSelfMs;
    