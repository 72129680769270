
// 第二層 ROUTER 
// 系統管理
let SysObjectMs = {
  path: '/SysObjectMs',//'/SysObjectMs',
  redirect: '/SysObjectMs/AdministrativeDivision',//'/SysObjectMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'AdministrativeDivision',
      name: 'AdministrativeDivision',
      component: () => import('@/views/php/SysObjectMs/AdministrativeDivision/PageRecord.vue'),
    },
    {
      path: 'PostalCode',
      name: 'PostalCode',
      component: () => import('@/views/php/SysObjectMs/PostalCode/PageRecord.vue'),
    },
    {
      path: 'County',
      name: 'County',
      component: () => import('@/views/php/SysObjectMs/County/PageRecord.vue'),
    },
    {
      path: 'SsoEducationSystem',
      name: 'SsoEducationSystem',
      component: () => import('@/views/php/SysObjectMs/SsoEducationSystem/PageRecord.vue'),
    },
    {
      path: 'BuildingType',
      name: 'BuildingType',
      component: () => import('@/views/php/SysObjectMs/Building/BuildingType/PageRecord.vue'),
    },
    {
      path: 'BuildingStructure',
      name: 'BuildingStructure',
      component: () => import('@/views/php/SysObjectMs/Building/BuildingStructure/PageRecord.vue'),
    },
    {
      path: 'UseDescription',
      name: 'UseDescription',
      component: () => import('@/views/php/SysObjectMs/Building/UseDescription/PageRecord.vue'),
    },
    {
      path: 'GeometryRules',
      name: 'GeometryRules',
      component: () => import('@/views/php/SysObjectMs/Building/GeometryRules/PageRecord.vue'),
    },
    {
      path: 'ShockproofType',
      name: 'ShockproofType',
      component: () => import('@/views/php/SysObjectMs/Building/ShockproofType/PageRecord.vue'),
    },
    {
      path: 'UseStatus',
      name: 'UseStatus',
      component: () => import('@/views/php/SysObjectMs/Building/UseStatus/PageRecord.vue'),
    },
    {
      path: 'UseDensity',
      name: 'UseDensity',
      component: () => import('@/views/php/SysObjectMs/Building/UseDensity/PageRecord.vue'),
    },
    {
      path: 'ObjectsType',
      name: 'ObjectsType',
      component: () => import('@/views/php/SysObjectMs/ObjectsType/PageRecord.vue'),
    },
    {
      path: 'OperationalRegime',
      name: 'OperationalRegime',
      component: () => import('@/views/php/SysObjectMs/Institution/OperationalRegime/PageRecord.vue'),
    },
    {
      path: 'InstitutionLevel',
      name: 'InstitutionLevel',
      component: () => import('@/views/php/SysObjectMs/Institution/InstitutionLevel/PageRecord.vue'),
    },
    {
      path: 'InstitutionType',
      name: 'InstitutionType',
      component: () => import('@/views/php/SysObjectMs/Institution/InstitutionType/PageRecord.vue'),
    },
    {
      path: 'InstitutionAttribute',
      name: 'InstitutionAttribute',
      component: () => import('@/views/php/SysObjectMs/Institution/InstitutionAttribute/PageRecord.vue'),
    },
    {
      path: 'AcademicYears',
      name: 'AcademicYears',
      component: () => import('@/views/php/SysObjectMs/Institution/AcademicYears/PageRecord.vue'),
    },
    {
      path: 'FillingStatus',
      name: 'FillingStatus',
      component: () => import('@/views/php/SysObjectMs/Institution/FillingStatus/PageRecord.vue'),
    },
    {
      path: 'ClassType',
      name: 'ClassType',
      component: () => import('@/views/php/SysObjectMs/Institution/ClassType/PageRecord.vue'),
    },
    {
      path: 'EnvironmentFacility',
      name: 'EnvironmentFacility',
      component: () => import('@/views/php/SysObjectMs/Room/EnvironmentFacility/PageRecord.vue'),
    },
    {
      path: 'RoomType',
      name: 'RoomType',
      component: () => import('@/views/php/SysObjectMs/Room/RoomType/PageRecord.vue'),
    },
    {
      path: 'MessageType',
      name: 'MessageType',
      component: () => import('@/views/php/SysObjectMs/Message/MessageType/PageRecord.vue'),
    },
    {
      path: 'PushNotificationType',
      name: 'PushNotificationType',
      component: () => import('@/views/php/SysObjectMs/Message/PushNotificationType/PageRecord.vue'),
    },
    {
      path: 'ImageType',
      name: 'ImageType',
      component: () => import('@/views/php/SysObjectMs/ImageType/PageRecord.vue'),
    },
    {
      path: 'VideoType',
      name: 'VideoType',
      component: () => import('@/views/php/SysObjectMs/Video/VideoType/PageRecord.vue'),
    },
    {
      path: 'VideoResolution',
      name: 'VideoResolution',
      component: () => import('@/views/php/SysObjectMs/Video/VideoResolution/PageRecord.vue'),
    },
    {
      path: 'ParkType',
      name: 'ParkType',
      component: () => import('@/views/php/SysObjectMs/ParkType/PageRecord.vue'),
    },
    {
      path: 'IconType',
      name: 'IconType',
      component: () => import('@/views/php/SysObjectMs/IconType/PageRecord.vue'),
    },
    {
      path: 'InternetOfThingsType',
      name: 'InternetOfThingsType',
      component: () => import('@/views/php/SysObjectMs/InternetOfThingsType/PageRecord.vue'),
    },
    {
      path: 'TasksType',
      name: 'TasksType',
      component: () => import('@/views/php/SysObjectMs/TasksType/PageRecord.vue'),
    },
    {
      path: 'TasksMode',
      name: 'TasksMode',
      component: () => import('@/views/php/SysObjectMs/TasksMode/PageRecord.vue'),
    },
  ]
};
  
export default SysObjectMs;
  