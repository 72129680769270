<template>
  <!-- Navbar -->
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
        <li class="breadcrumb-item text-sm">
          <a title="回到首頁" class="text-dark" href="javascript:;" @click.stop="goToPage('/Home')">
            <i class="material-icons text-lg position-relative py-1" title="請點選回到首頁">home</i>
          </a>
        </li>
        <li v-show="layer1 != null && layer1Name != null" class="breadcrumb-item text-sm">
          <a title="回到1" class="opacity-5 text-dark" :style="{ cursor: 'default' }" href="javascript:;">
            {{ layer1Name }}
            <span class="sr-only">sr-only</span>
          </a>
        </li>
        <li v-show="layer2 != null && layer2Name != null" class="breadcrumb-item text-sm">
          <a title="回到2" class="opacity-5 text-dark" :style="{ cursor: 'default' }" href="javascript:;"
            >{{ layer2Name }}
            <span class="sr-only">sr-only</span>
          </a>
        </li>
        <li v-show="layer3 != null && layer3Name != null" class="breadcrumb-item text-sm text-dark active" aria-current="page">
          {{ layer3Name }}
        </li>
      </ol>
      <h6 v-if="layer3Name != null && layer3Name != ''" class="font-weight-bolder mb-0">
        {{ layer3Name }}
      </h6>
      <h6 v-else-if="layer2Name != null && layer2Name != ''" class="font-weight-bolder mb-0">
        {{ layer2Name }}
      </h6>
    </nav>
    <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
      <a title="選單" href="javascript:;" class="nav-link p-0">
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line bg-dark" />
          <i class="sidenav-toggler-line bg-dark" />
          <i class="sidenav-toggler-line bg-dark" />
        </div>
        <span class="sr-only">sr-only</span>
      </a>
    </div>

    <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
      <div class="ms-md-auto pe-md-3 d-flex align-items-center">
        <div class="input-group input-group-outline">
          <!-- NTCCM-488
            <label class="form-label">Search here</label>
            <input
              type="text"
              class="form-control"
              onfocus="focused(this)"
              onfocusout="defocused(this)"
            >
            -->
        </div>
      </div>
      <ul class="navbar-nav justify-content-end">
        <li class="nav-item px-3">
          <a title="點選登出本系統" class="nav-link p-0" @click.stop="logout()">
            <i class="material-icons fixed-plugin-button-nav cursor-pointer" title="點選登出本系統"> logout </i>
          </a>
        </li>

        <li class="nav-item pr-3" @click="goToPage('/AdministrativeMs/PlatformGuide')">
          <a title="平台使用導引" href="javascript:;" class="nav-link p-0">
            <i class="material-icons fixed-plugin-button-nav cursor-pointer" title="平台使用導引"> auto_stories </i>
          </a>
        </li>

        <li class="nav-item">
          <a title="ProfileOverview" href="javascript:;" class="nav-link p-0 position-relative" @click.stop="goToPage('/AdminUserSelfMs/ProfileOverview')">
            <i class="material-icons me-sm-1" title="個人資訊"> account_circle </i>
          </a>
        </li>
        <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
          <a title="選單" href="javascript:;" class="nav-link p-0" id="iconNavbarSidenav">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line bg-dark" />
              <i class="sidenav-toggler-line bg-dark" />
              <i class="sidenav-toggler-line bg-dark" />
            </div>
            <span class="sr-only">sr-only</span>
          </a>
        </li>
        <li class="nav-item px-3">
          <a title="個人設定" href="javascript:;" class="nav-link p-0" @click.stop="goToPage('/AdminUserSelfMs/ProfileSettings')">
            <i class="material-icons fixed-plugin-button-nav cursor-pointer" title="個人設定"> settings </i>
          </a>
        </li>
        <li class="nav-item dropdown pe-2">
          <a title="訊息通知" href="javascript:;" class="nav-link p-0 position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="material-icons cursor-pointer" title="訊息通知"> notifications </i>
            <span v-if="unreadMessageCount != 0" class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
              <span class="small" :title="'您有' + unreadMessageCount + '則通知'">{{ unreadMessageCount }}</span>
              <span class="visually-hidden">unread notifications</span>
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end p-2 me-sm-n4" :style="'width:300px'" aria-labelledby="dropdownMenuButton">
            <template v-for="(item, index) in recordData" :key="item">
              <template v-for="rItem in rtAllRecord.MessageType" :key="rItem">
                <li
                  class="list-group-item border-0 d-flex align-items-center px-0 pt-0"
                  :class="recordData.length == index + 1 ? 'pb-0' : 'mb-2'"
                  v-if="rItem.mtid == item.mtid"
                  @click="goToPage('/AdminUserSelfMs/MessageContent', { mid: item.mid })"
                >
                  <div class="avatar border-radius-xl me-3" :class="'bg-' + rItem.color">
                    <i class="material-icons text-white opacity-10" aria-hidden="true">{{ rItem.icon }}</i>
                  </div>
                  <div class="d-flex align-items-start flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :title="item.title">
                      <template v-if="item.title.length > 9 && item.title != null && item.title"> {{ item.title.substr(0, 9) }}... </template>
                      <template v-if="item.title.length < 10 && item.title != null && item.title">
                        {{ item.title }}
                      </template>
                    </h6>
                    <p class="mb-0 text-xs">
                      {{ rItem.name }}
                    </p>
                  </div>
                  <a title="瀏覽" class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto">瀏覽</a>
                </li>
              </template>
            </template>
            <template v-if="recordData && recordData.length == 0">
              <li class="list-group-item border-0 d-flex align-items-center px-0 py-0">
                <div class="avatar border-radius-xl me-3 bg-gray-300">
                  <i class="material-icons text-white opacity-10" aria-hidden="true">error_outline</i>
                </div>
                <div class="d-flex align-items-start flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :title="'尚無訊息通知'">尚無訊息通知</h6>
                </div>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, toRefs, reactive, watch, inject, onMounted, onUpdated, onBeforeUpdate } from "vue";
import { useRouter, useRoute } from "vue-router";
import { config, oltConsole, oAA, oApiError, oAdminFrame, oUserInfo, oAuth, oUrl, oEduSysSchoolRt } from "@/lib/oltModule/initAdminVue3";
//import moment from 'moment' ;
import { useI18n } from "vue-i18n";
//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
import { useStore } from "vuex";

import { FontIcon, SvgIcon } from "@progress/kendo-vue-common";
import { globeIcon } from "@progress/kendo-svg-icons";
import { Popup } from "@progress/kendo-vue-popup";
import { ComboBox } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button, ButtonGroup, DropDownButton } from "@progress/kendo-vue-buttons";
import { Tooltip } from "@progress/kendo-vue-tooltip";
export default {
  name: "App",
  components: {
    //Loading,
    //Popup ,
    //ComboBox,
    //KButton : Button,
    //KButtonGroup : ButtonGroup,
    //DropDownButton : DropDownButton ,
    //Tooltip : Tooltip,
    //FontIcon,
    //SvgIcon,
  },
  props: {
    routerChangeNumber: {
      type: Number,
      required: 0,
    },
  },
  setup(props, { emit }) {
    const { routerChangeNumber } = toRefs(props);

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    /**
     *  swal start
     */
    const swal = inject("$swal");
    /**
     *  swal end
     */

    /**
     *  i18n Start
     */
    const { t, locale } = useI18n({
      useScope: "global", // 必需設定 SCOPE 才能覆蓋設定
    });

    locale.value = "zh-TW";
    /**
     *  i18n end
     */

    const rounterName = ref("");
    const rounterPath = ref("");
    const layer1 = ref(null);
    const layer2 = ref(null);
    const layer3 = ref(null);
    const layer1Name = ref(null);
    const layer2Name = ref(null);
    const layer3Name = ref(null);

    //const locationUrl = ref(oUrl.getStoreLocationUrl(store));

    //console.log('NavBar LocationUrl : ' , locationUrl);

    const userProfile = ref();

    userProfile.value = oAuth.getUserProfile(store);

    const apiName = ref("message");
    const pageNumber = ref(1);
    const pageSize = ref(3);
    const pageSort = ref("created_at");
    const pageSortBy = ref("desc");
    const rtAllRecord = ref({});
    const pageRecord = ref({});
    const recordData = ref({});
    const rtPageRecord = ref({});
    let routerPath = route.path;
    const routerArr = routerPath.split("/");
    const module = routerArr[1];
    const fun = routerArr[2];
    const mcid = ref(1);

    function drawPageRecord() {
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiGetPageDebug: false, // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug: false, // rtAllRecord Debug
        apiGetRtPageRecordDebug: false, // API getRt 除錯模式
        // API Filter
        apiName: apiName.value,
        pageNumber: pageNumber.value, // int32
        pageSize: pageSize.value, // int 32
        pageSort: pageSort.value, // string
        pageSortBy: pageSortBy.value, // string
        /**
         *  customFilter
         *  客制化 .net core 的 filter
         *  預設 是 false
         *  如果啟動 pageSearch 本來是 帶 JSON 字串 會將欄位全部轉換成 API 的參數 帶出去
         *  EX ： api/?pageSearch={fid:1,name:'test'} => api/？fid=1&name=test&pageSearch={fid:1,name:'test'}
         * */
        customFilter: false,
        pageSearch: {
          uid: userProfile.value.admin_users.uid,
          mcid: mcid.value,
        }, // json format
        pageGroup: "", // string
        pageGroupBy: "", // string
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt
        rtSet: {
          MessageType: {
            type: "all",
            apiName: "message_type",
            pageSearch: { switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: "mtid", // string
            pageSortBy: "asc", // string
          },
          MessageCount: {
            type: "all",
            apiName: "messages_count",
            pageSearch: { uid: [userProfile.value.admin_users.uid], switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: "created_at", // string
            pageSortBy: "desc", // string
          },
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        module: module, // module
        fun: "ProfileMessages", // fun
        act: "view", // act
      };

      oAA.queueGetAllVue3(apiParam).then((res) => {
        // console.log('NAV res==>',res);
        rtAllRecord.value = res.rtAllRecord;
        pageRecord.value = res.pageRecord;
        recordData.value = pageRecord.value.data;
        rtPageRecord.value = res.rtPageRecord;
        mergeData();
      });
    }

    const unreadMessageCount = ref(0);

    function mergeData() {
      let count = 0;

      if (rtAllRecord.value && rtAllRecord.value.MessageCount) {
        if (rtAllRecord.value.MessageCount.sys_count != 0) {
          count = count + rtAllRecord.value.MessageCount.sys_count;
        }
        if (rtAllRecord.value.MessageCount.alert_count != 0) {
          count = count + rtAllRecord.value.MessageCount.alert_count;
        }
        if (rtAllRecord.value.MessageCount.event_count != 0) {
          count = count + rtAllRecord.value.MessageCount.event_count;
        }
        if (rtAllRecord.value.MessageCount.news_count != 0) {
          count = count + rtAllRecord.value.MessageCount.news_count;
        }
        if (rtAllRecord.value.MessageCount.school_admin_count != 0) {
          count = count + rtAllRecord.value.MessageCount.school_admin_count;
        }
        if (rtAllRecord.value.MessageCount.drawing_count != 0) {
          count = count + rtAllRecord.value.MessageCount.drawing_count;
        }
      }
      unreadMessageCount.value = count;
    }

    /**
     *  initAdmion.js Start
     *  修改新增資料 在 刷新時重新初始化 material dashboard 的 js 設定
     */
    // Reload MDInput Start
    const initInput = async () => {
      //console.log('NavBar onMounted');
      // eslint-disable-next-line no-undef
      await oAdminFrame.initMdInput();
      if (userProfile.value && userProfile.value.admin_users && userProfile.value.admin_users.uid) {
        drawPageRecord();
      }

      reloadRouter();

      let className = 'g-sidenav-pinned';
      let body = document.getElementsByTagName('body')[0];

      body.classList.add(className);
    };

    onMounted(initInput);
    // Reload MDInput End

    onUpdated(async () => {
      //console.log('NavBar onUpdated');
      // 修改 VUE 時 必需全部強制關閉 Modals
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    });
    /**
     *  initAdmion.js End
     */

    onBeforeUpdate(async () => {
      //console.log('NavBar onBeforeUpdate');
    });

    watch(routerChangeNumber, (newValue, oldValue) => {
      //console.log('watch routerChangeNumber : ', newValue);

      reloadRouter();
    });

    function logout() {
      oEduSysSchoolRt.cleanEduSysSchoolRt(store);
      oAuth.logout(router, store, swal, t);
    }

    function goToPage(page, qobj) {
      let goToUrl = { path: page, query: qobj };

      oUrl.routerPushToUrl(router, store, goToUrl);

      if (rounterPath.value == page) {
        router.go(0);
      } else if (page == "/AdministrativeMs/PlatformGuide" || page == "/AdminUserSelfMs/ProfileOverview" || page == "/AdminUserSelfMs/ProfileSettings" || page == "/AdminUserSelfMs/MessageContent") {
        router.afterEach((to, from, next) => {
          router.go(0);
        });
      }
    }

    function reloadRouter() {
      //console.log('old rounterName : ', rounterName.value);
      //console.log('old rounterPath : ', rounterPath.value);
      //console.log('route : ', route);
      //console.log('router : ', router);
      //console.log('router.currentRoute : ', router.currentRoute);
      //console.log('router.currentRoute.value : ', router.currentRoute.value);
      //console.log('router.currentRoute.value.name : ', router.currentRoute.value.name);
      //console.log('router.currentRoute.value.path : ', router.currentRoute.value.path);

      let isChange = false;

      if (router != null && router.currentRoute != null && router.currentRoute.value != null) {
        if (router.currentRoute.value.name != null && router.currentRoute.value.name != rounterName.value) {
          rounterName.value = router.currentRoute.value.name;
          isChange = true;
        }
        if (router.currentRoute.value.path != null && router.currentRoute.value.path != rounterPath.value) {
          rounterPath.value = router.currentRoute.value.path;
          isChange = true;
        }
      }
      //console.log('rounterName : ', rounterName.value);
      //console.log('rounterPath : ', rounterPath.value);
      //console.log('isChange : ', isChange);
      let funData = null;
      let moduleData = null;
      let funNodeData = null;

      if (isChange == true) {
        funData = oUserInfo.getAdminFunsByFunsCode(store, rounterName.value);
        moduleData = oUserInfo.getAdminModulesByFunsCode(store, rounterName.value);
        if (funData != null && funData.node != null && funData.node > 0) {
          funNodeData = oUserInfo.getAdminFunsByFunsID(store, funData.node);
        }

        // setting layer1
        if (moduleData != null) {
          layer1.value = moduleData;
          layer1Name.value = moduleData.name;
        } else {
          layer1.value = null;
          layer1Name.value = null;
        }

        // setting layer2
        if (funNodeData != null) {
          layer2.value = funNodeData;
          layer2Name.value = funNodeData.name;
        } else if (funData != null) {
          layer2.value = funData;
          layer2Name.value = funData.name;
        } else {
          layer2.value = null;
          layer2Name.value = null;
        }

        // setting layer3
        if (funNodeData != null && funData != null) {
          layer3.value = funData;
          layer3Name.value = funData.name;
        } else {
          layer3.value = null;
          layer3Name.value = null;
        }
      }
      //console.log('funData : ', funData);
      //console.log('moduleData : ', moduleData);
      //console.log('funNodeData : ', funNodeData);
    }

    return {
      // i18n
      t,
      locale,
      // const data
      rounterName,
      rounterPath,
      layer1,
      layer2,
      layer3,
      layer1Name,
      layer2Name,
      layer3Name,
      // function
      goToPage,
      logout,
      rtAllRecord,
      pageRecord,
      recordData,
      rtPageRecord,
      unreadMessageCount,
    };
  },
};
</script>
<style scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
