<template>
  <form-element :style="{ maxWidth: '100%' }">
    <field
      :id="'login_type'"
      :name="'login_type'"
      :label="default_value"
      :component="'myTemplate'"
      :data="items"
      :textField="'label'"
      :validator="loginTypeValidator"
    >
      <template #myTemplate="{ props }">
        <FloatingLabelFormDropDownList
          v-bind="props"
          @onchange="onChangeLoginType"
          @change="props.onChange"
          @blur="props.onBlur"
          @focus="props.onFocus"
        />
      </template>
    </field>
    <template v-if="loginType == 'sys'">
      <field
        :id="'account'"
        :name="'account'"
        :component="'myTemplate'"
        :label="'帳 號'" 
        :option="'true'"
        :type="'text'"
        :validator="accountValidator"
      >
        <template #myTemplate="{props}">
          <formfloatinglabelinput
            v-bind="props"
            @change="props.onChange"
            @blur="props.onBlur"
            @focus="props.onFocus"
          />
        </template>
      </field>

      <field
        :id="'password'"
        :name="'password'"
        :component="'myTemplate'"
        :label="'密 碼'" 
        :option="'true'"
        :type="'password'"
        :validator="passwordValidator"
      >
        <template #myTemplate="{props}">
          <formfloatinglabelinput
            v-bind="props"
            @change="props.onChange"
            @blur="props.onBlur"
            @focus="props.onFocus"
          />
        </template>
      </field>
    </template>

    <div class="k-form-buttons d-flex justify-content-center">
      <template v-if="loginType == 'sso'">
        <KButton
          class="w-85"
          :style="'height: 50px; font-size: 15px;'"
          :togglable="true"
          :theme-color="'primary'"
          :icon-class="'fa fa-right-to-bracket fa-xs'"
          type="button"    
          @click="onSSOLogin"  
        >
        前往新北市政府教育局單一簽入系統
        </KButton>
      </template>
      <template v-if="loginType == 'sys'">
        <KButton
          :togglable="true"
          :theme-color="'primary'"
          :icon-class="'fa fa-right-to-bracket fa-xs'"
          :size="'large'"
          type="submit"      
          :disabled="!kendoForm.allowSubmit"
        >
          登入
        </KButton>
        &#160;
        <KButton
          type="button"      
          :icon-class="'fa-light fa-hand-sparkles fa-xs'"
          :size="'large'"
          @click="clear"
        >
          重置 
        </KButton>
      </template>

    </div>
  </form-element>
</template>
<script>
import { ssoConfig } from '@/include/config';
import { Field, FormElement } from '@progress/kendo-vue-form';
import FormInput from '@/components/OltFormField/LabelFormInput.vue';
import FormFloatingLabelInput from '@/components/OltFormField/FloatingLabelFormInput.vue';
//import FormComboBox from '@/components/OltFormField/FormComboBox.vue';
//import FloatingLabelFormComboBox from '@/components/OltFormField/FloatingLabelFormComboBox.vue';
import FloatingLabelFormDropDownList from '@/components/OltFormField/FloatingLabelFormDrowDownList';
import { Button } from '@progress/kendo-vue-buttons';

import {
  loginTypeValidator,
  accountValidator,
  passwordValidator,
} from '@/lib/oltValidators';


const items = [
  { label: '新北市教育局 SSO 帳號', value: 'sso' },
  { label: '系統預設帳號', value: 'sys' },
] ;

export default {
  components: {
    field: Field,
    'form-element': FormElement,
    //'forminput': FormInput,
    'formfloatinglabelinput':FormFloatingLabelInput,
    //'FormComboBox': FormComboBox,
    FloatingLabelFormDropDownList,
    'KButton': Button
  },
  inject: {
    kendoForm: { default: {} },  
  },
  data: function () {
    return { 
      default_value : '請選擇認證方式',
      loginType: 'sso',
      items:items.slice() ,
      //value:{ label: '新北市教育局 SSO 帳號', value: 'sso' },
      //value:{ label: '新北市教育局 SSO 帳號', value: 'sso' },
      loginTypeValidator,
      accountValidator,
      passwordValidator,
    };
  },
  methods: {
    handleSubmit (dataItem) {
      alert(JSON.stringify(dataItem, null, 2));
    },
    clear(){
      this.kendoForm.onFormReset();
      this.kendoForm.values.login_type = this.items[0];
      this.loginType = this.items[0].value;
    },
    onChangeLoginType(e){
      this.loginType = e.value.value;
    },
    onSSOLogin(){
      let goToUrl = `${ssoConfig.protocol}${ssoConfig.domain}${ssoConfig.path}`;
      window.location.href = goToUrl;
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.clear();
    });
  },
};
</script>