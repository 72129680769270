
// 第二層 ROUTER 
// 系統管理
let SysMs = {
  path: '/SysMs',
  redirect: '/SysMs/AdminRoles',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      path: 'AdminRoles',
      name: 'AdminRoles',
      component: () => import('@/views/php/SysMs/AdminRoles/Page.vue'),
    },
    /*
    {
      path: 'AdminUsersJoin',
      name: 'AdminUsersJoin',
      component: () => import('@/views/php/SysMs/AdminUsersJoin/PageRecord.vue'),
    },
    */
    {
      path: 'AdminUsers',
      name: 'AdminUsers',
      component: () => import('@/views/php/SysMs/AdminUsers/Page.vue'),
    },    
    {
      path: 'AdminRoleFunRt',
      name: 'AdminRoleFunRt',
      component: () => import('@/views/php/SysMs/AdminRoleFunRt/PageRecord.vue'),
    },
    /*
    {
      path: 'AdminModules',
      name: 'AdminModules',
      component: () => import('@/views/php/SysMs/AdminModules/PageRecord.vue'),
    },
    {
      path: 'AdminFuns',
      name: 'AdminFuns',
      component: () => import('@/views/php/SysMs/AdminFuns/PageRecord.vue'),
    }
    */
    { 
      path: 'AdminRoleFunRtGroups',
      name: 'AdminRoleFunRtGroups',
      component: () => import('@/views/php/SysMs/AdminRoleFunRtGroups/PageRecord.vue'),
    },
    { 
      path: 'MessageConfig',
      name: 'MessageConfig',
      component: () => import('@/views/php/SysMs/MessageConfig/PageRecord.vue'),
    },
  ]
};

export default SysMs;
