<template>
  <aside
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 ps ps--active-y bg-white bg-nav-white "
    id="sidenav-main"
    style="overflow-y: auto !important;"
  >
    <div class="sidenav-header">
      <i
        class="fas fa-times p-3 cursor-pointer text-dark opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      />
      <a
        class="navbar-brand m-0"
        href="javascript:;"
        target="_blank"
      >
        <img
          src="@/assets/img/logo-big.png"
          class="navbar-brand-img h-100"
          alt="main_logo"
        >

        &#160;
        <span class="ms-1 font-weight-bold text-dark">校園平面繪圖系統</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2">
    <div
      class="collapse navbar-collapse  w-auto h-auto overflow-hidden"
      id="sidenav-collapse-main"
    >
      <ul class="navbar-nav">
        <li class="nav-item mb-2 mt-0">
          <a
            data-bs-toggle="collapse"
            href="#ProfileNav"
            class="nav-link text-dark"
            :class="{ active: 'AdminUserSelfMs' == routerPathArr[1] }"
            aria-controls="ProfileNav"
            role="button"
            aria-expanded="false"
          >
            <img
              v-if="userIcon == null || userIcon == ''"
              src="@/assets/img/user.jpg"
              class="avatar"
              alt="avatar"
            >
            <img
              v-if="userIcon != null && userIcon != ''"
              :src="userIcon" 
              class="avatar"
              alt="avatar"
              @error="setDefaultImage"
            >

            <span class="nav-link-text ms-2 ps-1">{{ userName }}</span>
          </a>
          <div
            class="collapse"
            :class="{ show: 'AdminUserSelfMs' == routerPathArr[1] }"
            id="ProfileNav"
            style=""
          >
            <ul class="nav ">
              <li
                class="nav-item"
                :class="{ active: 'ProfileOverview' == routerPathArr[2] }"
              >
                <!--
                <a
                  class="nav-link text-dark"
                  :class="{ active: 'ProfileOverview' == routerPathArr[2] }"
                  href="javascript:;"
                  @click="gotoOwnProfile()"
                >
                  <i class="material-icons-round">
                    account_circle
                  </i>
                  <span class="sidenav-normal  ms-3  ps-1"> 個人資訊 </span>
                </a>-->
                <router-link
                  to="/AdminUserSelfMs/ProfileOverview"
                  class="nav-link text-dark"
                  :class="{ active: 'ProfileOverview' === routerPathArr[2] }"
                  @click="gotoOwnProfile()"
                >
                  <i class="material-icons-round">account_circle</i>
                  <span class="sidenav-normal ms-3 ps-1">個人資訊</span>
                </router-link>
              </li>
              <li
                class="nav-item"
                :class="{ active: 'ProfileSettings' == routerPathArr[2] }"
              >
                <!--
                <a
                  class="nav-link text-dark "
                  :class="{ active: 'ProfileSettings' == routerPathArr[2] }"
                  href="javascript:;"
                  @click="gotoOwnSetting()"
                >
                  <i class="material-icons-round">
                    settings
                  </i>
                  <span class="sidenav-normal  ms-3  ps-1"> 個人設定 </span>
                </a>-->
                <router-link
                  to="/AdminUserSelfMs/ProfileSettings"
                  class="nav-link text-dark"
                  :class="{ active: 'ProfileSettings' === routerPathArr[2] }"
                  @click="gotoOwnSetting()"
                >
                  <i class="material-icons-round">settings</i>
                  <span class="sidenav-normal ms-3 ps-1">個人設定</span>
                </router-link>
              </li>
              <li
                class="nav-item"
                :class="{ active: 'ProfileMessages' === routerPathArr[2] || 'MessageContent' === routerPathArr[2] || 'AllMessages' === routerPathArr[2]}"
              >
                <!--
                <a
                  class="nav-link text-dark "
                  :class="{ active: 'ProfileMessages' == routerPathArr[2] }"
                  href="javascript:;"
                  @click="gotoOwnMessage()"
                >
                  <i class="material-icons-round">
                    notifications
                  </i>
                  <span class="sidenav-normal  ms-3  ps-1"> 訊息通知 </span>
                </a>-->
                <router-link
                  to="/AdminUserSelfMs/ProfileMessages"
                  class="nav-link text-dark"
                  :class="{ active: 'ProfileMessages' === routerPathArr[2] || 'MessageContent' === routerPathArr[2] || 'AllMessages' === routerPathArr[2]}"
                  @click="gotoOwnMessage()"
                >
                  <i class="material-icons-round">notifications</i>
                  <span class="sidenav-normal ms-3 ps-1">訊息通知</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <hr class="horizontal light mt-0">
        <template v-if="dev.usePHPMenu">
          <PHPNav 
            :newPage="newPage"
            @changePage="changePage"
          />
        </template>
        <template v-if="dev.useDotNetMenu">
          <hr class="horizontal light mt-0">
          <DotnetNav />
        </template>
        <!--<hr class="horizontal light mt-0">
     
        <template 
          v-if="dev.showSideNav"
        >
          <li class="nav-item">
            <a
              data-bs-toggle="collapse"
              href="#dashboardsExamples"
              class="nav-link text-dark"
              aria-controls="dashboardsExamples"
              role="button"
              aria-expanded="false"
            >
              <i class="material-icons-round opacity-10">dashboard</i>
              <span class="nav-link-text ms-2 ps-1">DevSite</span>
            </a>
            <div
              class="collapse"
              id="dashboardsExamples"
            >
              <ul class="nav ">
                <li class="nav-item">
                  <a
                    class="nav-link text-dark"
                    href="/mdp/pages/dashboards/analytics.html"
                    target="_blank"
                  >
                    <span class="sidenav-mini-icon"> G </span>
                    <span class="sidenav-normal  ms-2  ps-1"> Go To DevSite </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </template>-->
      </ul>
    </div>
  </aside>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { config , dev , oltConsole, oAA , oApiError , oAdminFrame, oAuth, oUserInfo} from '@/lib/oltModule/initAdminVue3';
import moment from 'moment' ;
import { useI18n } from 'vue-i18n';
import { DotnetNav , PHPNav } from '@/components/Frame/NavMenu';
//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
import { useStore } from 'vuex';
import { userIconConfig } from '@/include/config';

export default {
  name: 'App',
  components: {
    DotnetNav,
    PHPNav,
  },
  setup() {

    /**
     *  swal start
     */
    const swal = inject('$swal') ;
    /**
     *  swal end 
     */

    /**
     *  i18n Start 
     */
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';
    /**
     *  i18n end 
     */

    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isLogin = ref(false);
    const isDefaultUserIcon = ref(true);
    const userName = ref('');
    const userIcon = ref('');
    const newPage = ref('');
 
    /**
     *  initAdmion.js Start
     *  修改新增資料 在 刷新時重新初始化 material dashboard 的 js 設定 
     */
    // Reload MDInput Start
    const initInput = async () => {
      // eslint-disable-next-line no-undef
      await oAdminFrame.initMdInput() ;

      let adminUser = null;

      //console.log('store : ', store);

      // 檢查是否已登入
      isLogin.value = oAuth.isLogin(store);

      // 已登入就取得使用者資訊
      if(isLogin.value == true){
        // adminUser = oAuth.getUser(store);
        adminUser = oUserInfo.getUserInfo(store).admin_users;
      }
      // console.log('adminUser : ', adminUser);

      if(adminUser != null){
        // 取得使用者名稱
        if(adminUser.name != null){
          userName.value = adminUser.nickname;
        }
        // 取得使用者頭像   
        if(adminUser.uid != null){
          let aDate = new Date();
          let iconPath = `${userIconConfig['protocol']}${userIconConfig['domain']}${userIconConfig['upload_path']}${adminUser.uid}/${userIconConfig['name']}?${aDate}`;
          // console.log("iconPath ==> ", iconPath);
          let img = new Image();

          img.setAttribute('crossOrigin','Anonymous');
          img.onload = function(){
            // console.log("img ==> ", img);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);
            userIcon.value = canvas.toDataURL();
            // console.log("userIcon ==> ", userIcon.value);
          };
          img.src = iconPath;
        }
      }
    };

    onMounted(initInput);
    // Reload MDInput End

    onUpdated( async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    }) ;
    /**
     *  initAdmion.js End
     */

    const routerPathArr = ref('');

    //routerPathArr.value = '/SysMs/AdminRoles'.split('/') ;
    routerPathArr.value = router.currentRoute.value.path.split('/');
    function goToPage(page) {
      newPage.value = page;
      routerPathArr.value = page.split('/');
      router.push({ path: page });
    }

    function gotoOwnProfile(){
      goToPage('/AdminUserSelfMs/ProfileOverview');
    }

    function gotoOwnSetting(){
      router.push({ path: '/AdminUserSelfMs/ProfileSettings' });
      goToPage('/AdminUserSelfMs/ProfileSettings');
    }

    function gotoOwnMessage(){
      goToPage('/AdminUserSelfMs/ProfileMessages');
    }

    function setDefaultImage(event){
      userIcon.value = '';
    }

    function changePage(page){
      routerPathArr.value = page.split('/');
    }


    return {
      dev,
      config,
      // moment 
      moment,
      // i18n
      t,
      locale,      
      // record pag
      fullPage: true,     
      // const data
      token:null,
      isLogin,
      userName,
      userIcon,
      // function
      gotoOwnProfile,
      gotoOwnSetting,
      gotoOwnMessage,
      setDefaultImage,
      routerPathArr,
      newPage,
      changePage,
    };
  },
};
</script>
<style scoped>
.bg-nav-white {
    background-color: #fff !important;
}
</style>