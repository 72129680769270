
// 第二層 ROUTER 
// 教務行政管理
let AdministrativeMs = {
  path: '/AdministrativeMs',
  redirect: '/AdministrativeMs/Dashboard',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {//平台使用導引
      path: 'PlatformGuide',
      name: 'PlatformGuide',
      component: () => import('@/views/php/AdministrativeMs/PlatformGuide/PageRecord.vue'),
    },
    {//我的任務列表
      path: 'TaskList',
      name: 'TaskList',
      component: () => import('@/views/php/AdministrativeMs/TaskList/PageRecord.vue'),
    },
    {//通知管理
      path: 'MessagesMs',
      name: 'MessagesMs',
      component: () => import('@/views/php/AdministrativeMs/MessagesMs/PageRecord.vue'),
    },
    {//繪圖政策管理
      path: 'DrawingPolicyMs',
      name: 'DrawingPolicyMs',
      component: () => import('@/views/php/AdministrativeMs/DrawingPolicyMs/PageRecord.vue'),
    },
    {//繪圖政策設定任務
      path: 'DrawingPolicyTaskSetting',
      name: 'DrawingPolicyTaskSetting',
      component: () => import('@/views/php/AdministrativeMs/DrawingPolicyTaskSetting/PageRecord.vue'),
    },
  ]
};

export default AdministrativeMs;
