// 第二層 ROUTER 
// 繪圖編輯
let EditArchitecture = {
    path: '/EditArchitecture',
    redirect: '/EditArchitecture',
    children: [
      {//編輯建築物
        path: 'EditArchitecture',
        name: 'EditArchitecture',
        component: () => import('@/views/php/EditArchitecture/EditArchitecture.vue'),
      },
      {//預覽建築物
        path: 'ReviewArchitecture',
        name: 'ReviewArchitecture',
        component: () => import('@/views/php/EditArchitecture/ReviewArchitecture.vue'),
      },
    ]
  };
  
  export default EditArchitecture;
  