<template>
  <template v-if="dev.showApiTitle">
    <li
      class="nav-item text-center"
    >
      DotnetCore API 
    </li>
  </template>
  <li
    class="nav-item"
    v-for="ritem in rtAllRecord.AdminModules" 
    :key="ritem"
  >
    <a
      data-bs-toggle="collapse"
      :href="'#'+ritem[rtTableName1].code"
      class="nav-link text-dark "
      :class="{'active':ritem[rtTableName1].code == routerPathArr[1]}" 
      aria-controls="ecommerceExamples"
      role="button"
      aria-expanded="false"
    >
      <i class="material-icons-round">
        {{ ritem[rtTableName1].icon }}
      </i>
      <span class="nav-link-text ms-2 ps-1">
        {{ ritem[rtTableName1].name }}
      </span>
    </a>
    <div
      class="collapse"
      :class="{'show':ritem[rtTableName1].code == routerPathArr[1]}" 
      :id="ritem[rtTableName1].code"
    >
      <ul class="nav ">
        <li
          class="nav-item"
          v-for="item in pageRecord" 
          :key="item"
        >
          <template
            v-if="
              item[tableName1].layer == 1 && item[tableName1].node == 0 && item[tableName1].nodeNum == 0 && item[tableName1].mid == ritem[rtTableName1].mid
            "
          >
            <li
              class="nav-item"
              :class="{'active':item[tableName1].code == routerPathArr[2] } " 
            >
              <a
                href="javascript:;"
                @click="goToPage('/'+ritem[rtTableName1].code+'/'+item[tableName1].code)"                      
                class="nav-link text-dark"
                :class="{'active':item[tableName1].code == routerPathArr[2] } " 
              >
                <span class="sidenav-mini-icon"> R </span>
                <span class="sidenav-normal  ms-2  ps-1">
                  {{ item[tableName1].name }}
                </span>
              </a>
            </li>
          </template>
          <template
            v-else-if="
              item[tableName1].layer == 1 && item[tableName1].node == 0 && item[tableName1].nodeNum > 0 && item[tableName1].mid == ritem[rtTableName1].mid
            "
          >
            <a
              class="nav-link text-dark "
              data-bs-toggle="collapse"
              aria-expanded="false"
              :href="'#'+item[tableName1].code"
              :class="{'active':item[tableName1].code == routerPathArr[2] } " 
            >
              <span class="sidenav-mini-icon"> {{ item[tableName1].nodeNum }} </span>
              <span class="sidenav-normal  ms-2  ps-1"> 
                {{ item[tableName1].name }}
                <b class="caret" /></span>
            </a>
            <div
              class="collapse"
              :id="item[tableName1].code"
            >
              <ul class="nav nav-sm flex-column">
                <li 
                  v-for="item2 in pageRecord" 
                  :key="item2"
                  class="nav-item"
                >
                  <template
                    v-if="
                      item2[tableName1].node == item[tableName1].code && item2[tableName1].mid == ritem[rtTableName1].mid
                    "
                  >
                    <a
                      class="nav-link text-dark "
                      :class="{'active':item2[tableName1].code == routerPathArr[2] } " 
                      href="javascript:;"
                      @click="goToPage('/'+ritem[rtTableName1].code+'/'+item2[tableName1].code)"     
                    >
                      <span class="sidenav-mini-icon"> O </span>
                      <span class="sidenav-normal  ms-2  ps-1"> 
                        {{ item2[tableName1].name }}
                      </span>
                    </a>
                  </template>
                </li>
              </ul>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </li>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { h, ref, reactive , watch , inject , onMounted , onUpdated} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { config , dev , oltConsole, oAA , oApiError , oAdminFrame} from '@/lib/oltModule/initAdminVue3';
import moment from 'moment' ;
import { useI18n } from 'vue-i18n';
//import Loading from 'vue-loading-overlay';
//import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'App',
  components: {
    //Loading,
  },
  setup() {

    /**
     *  swal start
     */
    const swal = inject('$swal') ;
    /**
     *  swal end 
     */

    /**
     *  i18n Start 
     */
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';
    /**
     *  i18n end 
     */
 
    /**
     *  Record Page Start 
     */
    const apiName = ref('AdminFuns');
    const rtTableName1 = ref('adminModules');

    const pkyKey = ref('rid');
    const tableName1 = ref(apiName.value.charAt(0).toLowerCase() + apiName.value.slice(1) );    
    const isLoading = ref(true);
    const pageRecordColumn = ref({});
    const pageNumber = ref(1);
    const pageFilter = ref('');

    const rtAllRecord = ref({});
    const pageRecord = ref({});
    const recordData = ref({});
    const rtPageRecord = ref({});

    // pageSort Start
    // use RecordColumn component 
    const pageSort = ref('seq');
    const pageSortBy = ref('asc');
    // pageSort End 

    // pageSize start 
    const pageSize = ref(5);
    // pageFilter End

    function drawPageRecord(){
      const apiParam = {
        // auth
        restApiDriver:'dotnetApiUrl' ,// token key from veex store 
        token:'' ,// token key from veex store 
        // Debug Mode 
        apiGetPageDebug:false , // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug:false , 
        apiGetRtPageRecordDebug:false , 
        // API Filter 
        apiName:apiName.value,
        pageNumber:pageNumber.value , // int32 
        pageSize:pageSize.value , // int 32
        pageSort:pageSort.value , // string 
        pageSortBy:pageSortBy.value , // string 
        /**
       *  customFilter
       *  客制化 .net core 的 filter 
       *  預設 是 false 
       *  如果啟動 pageSearch 本來是 帶 JSON 字串 會將欄位全部轉換成 API 的參數 帶出去
       *  EX ： api/?pageSearch={fid:1,name:'test'} => api/？fid=1&name=test&pageSearch={fid:1,name:'test'}
       * */ 
        customFilter:false, 
        pageFilter:pageFilter.value , // string
        pageSearch:{
          //name:pageSearchName.value,
          //code:pageSearchCode.value,
          //description:pageSearchDescription.value,
          //switch:pageSearchSwitch.value
        } , // json format 
        pageGroup:'' , // string 
        pageGroupBy:'' , // string 
        pageMode: false, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt 
        rtSet:{
          'AdminModules':{
            type:'all' , 
            apiName: rtTableName1.value.charAt(0).toUpperCase() + rtTableName1.value.slice(1),
            //pageSearch:{name:'admin'}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSearch:{switch:true}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort:'mid' , // string 
            pageSortBy:'asc' , // string                
          } , 
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
      };

      oAA.queueGetAllVue3(apiParam)
        .then(
          res => 
          {
            //return {rtAllRecord,pageRecord}
            //console.log(res);
            rtAllRecord.value = res.rtAllRecord ; 
            pageRecord.value = res.pageRecord ; 
            //recordData.value = pageRecord.value.data ; 
            recordData.value = getNodeNumRecord() ; 
            rtPageRecord.value = res.rtPageRecord ; 
            isLoading.value = false; 
          }
        )
        .catch((err)=> {
          oApiError.chkError(err,swal,t);  
          //console.log(err);
        });
    }

    drawPageRecord();
    //console.log('apiParam :' , apiParam);
    //console.log('recordData :' , recordData);

    function getNodeNumRecord(){
      //console.log('pageRecord : ' , pageRecord.value );
      if(pageRecord.value){
        for(const [prk, prv] of Object.entries(pageRecord.value)){
          if(prv[tableName1.value].node == 0){
            //console.log('pageRecord root node : ' , prk , prv[tableName1.value] );
            let nodeNum = 0 ; 

            for(const [pprk, pprv] of Object.entries(pageRecord.value)){
              if(
                pprv[tableName1.value].node != 0 && 
                pprv[tableName1.value].node == prv[tableName1.value].code 
              ){
                //console.log('pageRecord sub node : ' , pprk , pprv[tableName1.value] );
                nodeNum++ ; 

              }
              //console.log('pageRecord sub node : ' , pprk , pprv[tableName1.value] , nodeNum);
            }
            pageRecord.value[prk][tableName1.value].nodeNum = nodeNum ; 
            //console.log('pageRecord data : ' , pageRecord.value[prk][tableName1.value] );
            //console.log('pageRecord root node : ' , prk , prv[tableName1.value] , nodeNum);
          }
        }
      }
      return pageRecord ; 
    }


    function timeToDateTime(time){
      //console.log(moment(time).format('YYYY/MM/DD'));
      return moment(time).format('YYYY/MM/DD HH:mm:ss') ; 
    }
    /**
     *  Record Page End
     */

    const router = useRouter();
    const route = useRoute();

    //console.log('router : ' , router.currentRoute.value.path.split('/'));
    //console.log('router : ' , router.currentRoute.value);
    //const routerPathArr = router.currentRoute.value.path.split('/') ; 
    const routerPathArr = ref('');

    //routerPathArr.value = '/SysMs/AdminRoles'.split('/') ; 
    routerPathArr.value = router.currentRoute.value.path.split('/') ; 
    function goToPage(page){
      routerPathArr.value = page.split('/') ; 
      router.push( { path: page } );
    }

    /**
     *  initAdmion.js Start
     *  修改新增資料 在 刷新時重新初始化 material dashboard 的 js 設定 
     */
    // Reload MDInput Start
    const initInput = async () => {
      // eslint-disable-next-line no-undef
      await oAdminFrame.initMdInput() ;
    };

    onMounted(initInput);
    // Reload MDInput End

    onUpdated( async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals 
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    }) ;
    /**
     *  initAdmion.js End
     */

    return {
      dev,
      config,
      // moment 
      moment,
      // i18n
      t,
      locale,      
      // record page
      pageRecordColumn,
      pageNumber,
      pageSort,
      pageSortBy,
      pageSize,
      pageFilter,
      rtAllRecord,
      pageRecord,
      recordData,
      rtPageRecord,
      // Loading
      isLoading,
      fullPage: true,     
      // const data
      token:null,
      apiName,
      pkyKey,
      tableName1,
      rtTableName1,
      routerPathArr,
      // function 
      timeToDateTime,
      goToPage
    };
  },
};
</script>