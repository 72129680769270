<template>
  <footer class="footer py-4  ">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-center text-sm text-muted text-lg-start">
            © 2023,
            made with <i
              class="fa fa-heart"
              style="color:rgb(215,52,52);"
            /> by
            <a
            rel="noopener"
              href="https://cloudraise.com/"
              class="font-weight"
              target="_blank"
            >Cloud Raise Tech</a>
            for a better web.
          </div>
        </div>
        <div class="col-lg-6">
          <ul class="nav nav-footer justify-content-center justify-content-lg-end">
            <li class="nav-item">
              <a
              rel="noopener"
                href="https://cloudraise.com/"
                class="nav-link text-muted"
                target="_blank"
              >Cloud Raise Tech</a>
            </li>
            <li class="nav-item">
              <a
              rel="noopener"
                href="https://cloudraise.com/Company"
                class="nav-link text-muted"
                target="_blank"
              >About Us</a>
            </li>
            <li class="nav-item">
              <a
              rel="noopener"
                href="https://cloudraise.com/Products"
                class="nav-link text-muted"
                target="_blank"
              >Product</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line no-unused-vars
import { useI18n } from 'vue-i18n';
export default {
  props: {
    /* eslint-disable vue/require-default-prop */
    showmode: String,
    tname: String,
    hiddenHeader:Boolean,
    routerBack:Boolean,
    routerName:String,
    routerTitle:String,
    rightbtnname: String,
    onright:Function,
    onleft:Function,
    rightbtnstatus: String,
    righttype: String
  },
  data () {
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 

    locale.value = 'zh-TW';

    return { 
      //i18n
      t,
      locale,
      // params
      setDebugMode:false , // 該功能的除錯模式
      apiDebug:false , // API 除錯模式
      apiGetPageDebug:false , // API getPageRecord 除錯模式
      apiGetRtDebug:false , // API getRt 除錯模式
      apiGetOneDebug:false , // API getOneRecord 除錯模式
      apiPutDebug:false , // API 修改 除錯模式
      apiPostDebug:false , // API 新增 除錯模式
      apiDeleteDebug:false , // API 刪除 除錯模式
      platform:'client' ,    
      apiName:'user_role_priority' ,   
      apiParam:{
        uid:'',
      },
      conditionType:'pky',  
      pky:{'uid':'user_role_priority' }, 
      rtSet:{},
      record: [],
      totalPage:'',
      session: {},
      recordData: [],
      rtAllRecord:[],
      rtPageRecord:[],
      selectItems:{},
      perPageOptions: [5, 10, 25, 50 , 75 , 100],
      pageTab:0 ,
      mail_total:0,
      view_id:'',
      timer_id:'',
    };
  },
  created(){

  },
  mounted(){ 

  },
  methods:{
  },
};
</script>

