
// 第二層 ROUTER 
//訊息使用導引
let MessageMsGuide = {
  path: '/MessageMsGuide',
  redirect: '/MessageMsGuide/Dashboard',
  component: () => import('@/views/php/AdministrativeMs/MessageMsGuide/OltFrame.vue') ,
  children: [
    {
      path: 'MessageMsGuide',
      name: 'MessageMsGuide',
      component: () => import('@/views/php/AdministrativeMs/MessageMsGuide/PageRecord.vue'),
    },
  ]
};

export default MessageMsGuide;
