/* eslint-disable import/no-unresolved */
import { config, modulesList } from '@/include/config';
import {
  createRouter,
  createWebHistory,
  createWebHashHistory
  //RouteRecordRaw,
} from 'vue-router';
// 第一層 ROUTER
import Home from '@/views/Home.vue';

// 第二層 Router

// 系統管理
import AuthPage from '@/pages/index/router/AuthPage';

// .net core module
//import DCSysMs from '@/pages/index/router/dotnet/SysMs';
//import DCFunMs from '@/pages/index/router/dotnet/FunMs';

// php module
import SysMs from '@/pages/index/router/php/SysMs';
import SysObjectMs from '@/pages/index/router/php/SysObjectMs';
import SsoMs from '@/pages/index/router/php/SsoMs';
import SSOAuthPage from '@/pages/index/router/php/SSOAuthPage';
import DrawingMs from '@/pages/index/router/php/DrawingMs';
import SchoolBuildingObjectMs from '@/pages/index/router/php/SchoolBuildingObjectMs';
import AdministrativeMs from '@/pages/index/router/php/AdministrativeMs';
import EducationAdministrativeMs from '@/pages/index/router/php/EducationAdministrativeMs';
import EditArchitecture from '@/pages/index/router/php/EditArchitecture';
import UserGuide from '@/pages/index/router/php/UserGuide';
import AdminUserSelfMs from '@/pages/index/router/php/AdminUserSelfMs';
import MessageMsGuide from '@/pages/index/router/php/MessageMsGuide';
import UserGuideCreate from '@/pages/index/router/php/UserGuideCreate';
import SSOAutoLogin from '@/views/SSOAutoLogin.vue';






const routes = [
  /*
  {
    path: '/',
    redirect: '/SysMs/AdminRoles',
  },
  */
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/SSOAutoLogin',
    name: 'SSOAutoLogin',
    component: SSOAutoLogin
  },
  AdministrativeMs,
  SsoMs,
  SysMs,
  SysObjectMs,
  EducationAdministrativeMs,
  DrawingMs,
  SchoolBuildingObjectMs,
  EditArchitecture,
  ...UserGuide,
  AdminUserSelfMs,
  MessageMsGuide,
  UserGuideCreate,
];

if (modulesList != null) {
  if (modulesList.ssoSignIn != null && modulesList.ssoSignIn == true) {
    routes.push(SSOAuthPage);
  } else {
    routes.push(AuthPage);
  }
} else {
  routes.push(AuthPage);
}

const router = createRouter({
  //history: createWebHashHistory(config.baseUrl),
  //history: createWebHashHistory(),
  //history: createWebHistory(config.baseUrl),
  //history: createWebHistory(),
  history:
    process.env.NODE_ENV === 'production' ?
      createWebHashHistory() :
      createWebHistory(),
  linkActiveClass: 'cur',
  linkExactActiveClass: 'cur',
  routes
});

router.isReady().then(() => {
  //console.log('is Ready!!!!');
});

export default router;
