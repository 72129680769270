<template>
  <fieldwrapper>
    <div>
      <flabel
        :label="label"
        :editor-id="id"
        :editor-value="value"
        :editor-valid="valid"
        :disabled="disabled"
        :optional="optional"
      >
        <kinput
          :style="{ width: '100%' }"
          :valid="valid"
          :id="id"
          :type="type"
          :value="value"
          :disabled="disabled"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :inputSuffix="inputSuffix"
          @input="handleChange"
          @blur="handleBlur"
          @focus="handleFocus"
        >
        <template v-if="inputSuffix && inputSuffix != ''" v-slot:suffix>
          <template v-if="inputSuffixType == 'i'">
            <i :class="inputSuffixClass" @click="onSuffix"></i>
          </template>
          <template v-if="inputSuffixType == 'span'">
            <span :class="inputSuffixClass"></span>
          </template>
        </template>
        </kinput>
      </flabel>
      <error v-if="showValidationMessage">
        {{ validationMessage }}
      </error>
      <hint v-else>
        {{ hint }}
      </hint>
    </div>
  </fieldwrapper>
</template>
<script>
import { FieldWrapper } from '@progress/kendo-vue-form';
import { Error, Hint, FloatingLabel } from '@progress/kendo-vue-labels';
import { Input } from '@progress/kendo-vue-inputs';
export default {
  components: {
    fieldwrapper: FieldWrapper,
    error: Error,
    hint: Hint,
    flabel: FloatingLabel,
    kinput: Input
  },
  props: {
    optional: Boolean,
    disabled: Boolean,
    placeholder: String,
    touched: Boolean,
    label: String,
    validationMessage: String,
    hint: String,
    id: String,
    type: String,
    valid: Boolean,
    value: {
      type: String,
      default: ''
    },
    maxlength: Number,
    inputSuffix: String,
    inputSuffixType: String,
    inputSuffixClass: String,
  },
  emits: {
    change: null,
    blur: null,
    focus: null,
  },
  computed: {
    showValidationMessage() {
      return this.$props.touched && this.$props.validationMessage;
    },
    showHint() {
      return !this.showValidationMessage && this.$props.hint;
    },
    hintId() {
      return this.showHint ? `${this.$props.id}_hint` : '';
    },
    errorId() {
      return this.showValidationMessage ? `${this.$props.id}_error` : '';
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('onchange', e);
      this.$emit('change', e);
    },
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    onSuffix(e){
      this.$emit('onsuffix', e);
    }
  }
};
</script>
  