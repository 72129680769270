// 第二層 ROUTER 
// 繪圖管理 - 使用導引
let UserGuide = [
  {
    path: '/DrawingMs/UserGuide',
    name: '/DrawingMs/UserGuide',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step1.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep2',
    name: '/DrawingMs/UserGuideStep2',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step2.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep3',
    name: '/DrawingMs/UserGuideStep3',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step3.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4',
    name: '/DrawingMs/UserGuideStep4',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4_1',
    name: '/DrawingMs/UserGuideStep4_1',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4_1.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4_2',
    name: '/DrawingMs/UserGuideStep4_2',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4_2.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4_3',
    name: '/DrawingMs/UserGuideStep4_3',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4_3.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4_4',
    name: '/DrawingMs/UserGuideStep4_4',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4_4.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep4_5',
    name: '/DrawingMs/UserGuideStep4_5',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step4_5.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep5',
    name: '/DrawingMs/UserGuideStep5',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step5.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep5_1',
    name: '/DrawingMs/UserGuideStep5_1',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step5_1.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep5_2',
    name: '/DrawingMs/UserGuideStep5_2',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step5_2.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6',
    name: '/DrawingMs/UserGuideStep6',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6_1',
    name: '/DrawingMs/UserGuideStep6_1',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6_1.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6_2',
    name: '/DrawingMs/UserGuideStep6_2',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6_2.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6_3',
    name: '/DrawingMs/UserGuideStep6_3',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6_3.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6_4',
    name: '/DrawingMs/UserGuideStep6_4',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6_4.vue') ,
  },
  {
    path: '/DrawingMs/UserGuideStep6_5',
    name: '/DrawingMs/UserGuideStep6_5',
    component: () => import('@/views/php/DrawingMs/UserGuide/Step6_5.vue') ,
  },
];

export default UserGuide;
