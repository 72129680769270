<template>
  <SideNav />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ps ps--active-y">
    <NavBar />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <div class="card m-3" style="background-color: white; max-height: 70vh; border-radius: 0.75rem; overflow: hidden">
      <div class="row">
        <div class="col-6">
          <div style="max-height: 25vh" class="col-12">
            <img class="pl-5 w-90" src="../assets/img/bgback-01.jpg" alt="User Avatar" />
          </div>
          <div class="col-12 mx-5" style="height: 1000vh">
            <h6 class="mb-0 pl-3">動態訊息通知</h6>
            <div class="p-3 px-5 w-100">
              <div style="overflow: auto; height: 360px">
                <ul class="list-group" :style="'width:100%'">
                  <template v-for="item in recordData" :key="item">
                    <template v-for="(rItem, index) in rtAllRecord.MessageType" :key="index">
                      <olt-if v-if="rItem.mtid == item.mtid">
                        <li class="list-group-item border-0 d-flex align-items-center px-0 pt-0">
                          <div class="avatar border-radius-xl me-3" :class="' bg-' + rItem.color" :style="'width:48px'">
                            <i class="material-icons text-white opacity-10" aria-hidden="true">{{ rItem.icon }}</i>
                          </div>
                          <div class="d-flex align-items-start flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">
                              {{ rItem.name }}
                            </h6>
                            <p class="mb-0 text-xs" :title="item.title">
                              {{ item.title }}
                            </p>
                          </div>
                          <a title="瀏覽" class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" @click="goToPage('/AdminUserSelfMs/MessageContent', { mid: item.mid })">瀏覽</a>
                        </li>
                      </olt-if>
                    </template>
                    <hr style="width: 90%" />
                  </template>
                  <template v-if="recordData && recordData.length == 0">
                    <li class="list-group-item border-0 d-flex align-items-center px-0 mb-0 pt-0">
                      <div class="avatar border-radius-xl me-3 bg-gray-300">
                        <i class="material-icons text-white opacity-10" aria-hidden="true">error_outline</i>
                      </div>
                      <div class="d-flex align-items-start flex-column justify-content-center">
                        <h6 class="mb-0 text-sm" :title="'尚無訊息通知'">尚無訊息通知</h6>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 position-relative" style="max-height: 70vh">
          <img class="w-90 position-absolute bottom-10 end-2" src="../assets/img/bgback-02.jpg" alt="User Avatar" />
        </div>
      </div>
    </div>

    <Footer />
  </main>
</template>
<script>
/* eslint-disable import/no-absolute-path */
// eslint-disable-next-line import/no-unresolved
import { config } from "@/include/config";
import { useI18n } from "vue-i18n";
import { h, ref, toRefs, reactive, watch, inject, onMounted, onBeforeUpdate, onUpdated } from "vue";
import { oltConsole, oAA, oApiError, oAdminFrame, oUserInfo, oAuth, oUrl } from "@/lib/oltModule/initAdminVue3";
//import Header from '@/components/Header.vue';
import { SideNav, NavBar, Settings, Footer } from "@/components/Frame";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import "animate.css";

export default {
  name: "App",
  components: {
    SideNav,
    NavBar,
    // Settings,
    Footer,
    Loading,
  },
  setup() {
    /**
     *  swal start
     */
    const swal = inject("$swal");
    /**
     *  swal end
     */

    /**
     *  i18n Start
     */
    const { t, locale } = useI18n({
      useScope: "global", // 必需設定 SCOPE 才能覆蓋設定
    });

    locale.value = "zh-TW";
    /**
     *  i18n end
     */

    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    let routerPath = route.path;
    const routerArr = routerPath.split("/");
    const module = routerArr[1];
    const fun = routerArr[2];

    const apiName = ref("message");
    const isLoading = ref(true);

    const rtAllRecord = ref({});
    const pageRecord = ref({});
    const recordData = ref({});
    const rtPageRecord = ref({});

    // pageSort Start

    // use RecordColumn component
    const pageSort = ref("seq");
    const pageSortBy = ref("asc");

    // pageSize start
    const pageSize = ref(20);

    // pageSize end

    // pageFilter Start
    const pageFilter = ref("");

    // pageFilter End

    // Pagination Start
    const pageNumber = ref(1);

    // Pagination End

    const userProfile = ref();
    const mcid = ref(1);

    onMounted(async () => {
      chkLogin();
      userProfile.value = oUserInfo.getUserInfo(store);
      // console.log('userProfile.value',userProfile.value);
      drawPageRecord();
    });
    // Reload MDInput End

    onUpdated(async () => {
      // 修改 VUE 時 必需全部強制關閉 Modals
      // eslint-disable-next-line no-undef
      //await oAdminFrame.closeAllModals() ;
    });

    /**
     *  goToPage Start
     *  轉址方法，使用此方法會自動將路徑及參數放在最新的物件容器裡
     */

    function goToPage(page, qobj) {
      //console.log('route : ' , route);
      // console.log('router : ' , router.currentRoute.value);
      //let locationRouter = router.currentRoute.value ;
      let goToUrl = { path: page, query: qobj };

      oUrl.routerPushToUrl(router, store, goToUrl);
      //console.log( 'getStoreLocationUrl(store) : ' , oUrl.getStoreLocationUrl(store) ) ;
    }

    function chkLogin() {
      let isLogin = oAuth.isLogin(store);

      if (!isLogin) {
        router.push({ path: "/AuthPage/SignIn" });
      } else {
        chkEduSysSchoolRole();
      }
    }

    function chkEduSysSchoolRole() {
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiPostDebug: false, // API 新增 除錯模式
        apiName: "chk_sso_school",
        form: { login_type: "sso" },
        module: module, // module
        fun: fun, // fun
        act: "insert", // act
      };

      // console.log(apiParam);
      let resObj = oAA.postRecordVue3(apiParam);

      resObj
        .then((res) => {
          // console.log("res : ", res);

          let loginType = oAuth.getLoginType(store);

          if (loginType == "sso") {
            if (!res.chk_sso_school.default_edusys) {
              //console.log('這個帳號沒有選擇預設的學制或學校');
              swal
                .fire({
                  icon: "warning",
                  html: "您好，歡迎您首次使用本系統！<br>請按確認，開始設定您的基本資料。",
                  buttonsStyling: true,
                  confirmButtonClass: "md-button md-success",
                  confirmButtonText: t("messages.confirm_button"),
                  allowOutsideClick: false,
                })
                .then((result) => {
                  if (result.value) {
                    //console.log('result : ' , result);
                    router.push({ path: "/AuthPage/FirstLogin" });
                  }
                });
            }
          }
        })
        .catch((err) => {
          oApiError.chkError(err, swal);
        });
    }

    function drawPageRecord() {
      isLoading.value = true;
      const apiParam = {
        restApiDriver: config.restApiDriver, // token key from veex store
        // auth
        token: oAuth.getToken(store), // token key from veex store
        // Debug Mode
        apiGetPageDebug: false, // API getPageRecord 除錯模式
        apiGetRtAllRecordDebug: false, // rtAllRecord Debug
        apiGetRtPageRecordDebug: false, // API getRt 除錯模式
        // API Filter
        apiName: apiName.value,
        pageNumber: pageNumber.value, // int32
        pageSize: pageSize.value, // int 32
        pageSort: pageSort.value, // string
        pageSortBy: pageSortBy.value, // string
        /**
         *  customFilter
         *  客制化 .net core 的 filter
         *  預設 是 false
         *  如果啟動 pageSearch 本來是 帶 JSON 字串 會將欄位全部轉換成 API 的參數 帶出去
         *  EX ： api/?pageSearch={fid:1,name:'test'} => api/？fid=1&name=test&pageSearch={fid:1,name:'test'}
         * */
        customFilter: false,
        pageFilter: pageFilter.value, // string
        pageSearch: {
          uid: userProfile.value.admin_users.uid,
          switch: true,
          mcid: mcid.value,
        }, // json format
        pageGroup: "", // string
        pageGroupBy: "", // string
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt
        rtSet: {
          MessageType: {
            type: "all",
            apiName: "message_type",
            pageSearch: { switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: "mtid", // string
            pageSortBy: "asc", // string
          },
          MessageCount: {
            type: "all",
            apiName: "messages_count",
            pageSearch: { uid: [userProfile.value.admin_users.uid], switch: [true] }, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort: "created_at", // string
            pageSortBy: "desc", // string
          },
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        module: module, // module
        fun: fun, // fun
        act: "view", // act
      };

      // console.log("userProfile.value.admin_users", userProfile.value.admin_users);

      oAA
        .queueGetAllVue3(apiParam)
        .then((res) => {
          // console.log(res);
          rtAllRecord.value = res.rtAllRecord;
          pageRecord.value = res.pageRecord;
          recordData.value = pageRecord.value.data;
          rtPageRecord.value = res.rtPageRecord;
          isLoading.value = false;

          mergeData();
          // console.log("res:", res);
        })
        .catch((err) => {
          isLoading.value = false;
          oApiError.chkError(err, swal, t);
        });
    }

    // drawPageRecord();

    const unreadMessageCount = ref(0);

    function mergeData() {
      let count = 0;

      if (rtAllRecord.value && rtAllRecord.value.MessageCount) {
        if (rtAllRecord.value.MessageCount.sys_count != 0) {
          count = count + rtAllRecord.value.MessageCount.sys_count;
        }
        if (rtAllRecord.value.MessageCount.alert_count != 0) {
          count = count + rtAllRecord.value.MessageCount.alert_count;
        }
        if (rtAllRecord.value.MessageCount.event_count != 0) {
          count = count + rtAllRecord.value.MessageCount.event_count;
        }
        if (rtAllRecord.value.MessageCount.news_count != 0) {
          count = count + rtAllRecord.value.MessageCount.news_count;
        }
        if (rtAllRecord.value.MessageCount.school_admin_count != 0) {
          count = count + rtAllRecord.value.MessageCount.school_admin_count;
        }
        if (rtAllRecord.value.MessageCount.drawing_count != 0) {
          count = count + rtAllRecord.value.MessageCount.drawing_count;
        }
      }
      unreadMessageCount.value = count;

      if (unreadMessageCount.value && unreadMessageCount.value > 0) {
        let backPath = "";

        if (router.options.history.state.back) {
          backPath = router.options.history.state.back;
        }
        if (backPath.includes("AuthPage") == true) {
          swal.fire({
            icon: "warning",
            html: `您有${unreadMessageCount.value}則新通知！`,
            buttonsStyling: true,
            confirmButtonClass: "md-button md-success",
            confirmButtonText: t("messages.confirm_button"),
            showClass: {
              popup: "animate__animated animate__zoomInDown",
            },
          });
        }
      }
    }

    return {
      hiddenHeader: false,
      // api setting
      setDebugMode: true,
      apiDebug: false,
      apiName: "",
      perPage: 3, //筆數
      pageMode: true,
      perPageOptions: [5, 10, 25, 50, 75, 100],
      // data
      userInfo: { coin: 0, point: 0 },
      token: null,
      // function
      goToPage,
      chkLogin,
      // record page
      recordData,
      rtAllRecord,
      // const
      isLoading,
    };
  },
  created() {},
  mounted() {
    oAdminFrame.initMD();
  },
  methods: {},
};
</script>
