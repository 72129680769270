// 第二層 ROUTER 
// 繪圖管理
let SchoolBuildingObjectMs = {
  path: '/SchoolBuildingObjectMs',
  redirect: '/SchoolBuildingObjectMs/School',
  component: () => import('@/views/OltFrame.vue') ,
  children: [
    {
      // 學校資料管理
      path: 'School',
      name: 'School',
      component: () => import('@/views/php/SchoolBuildingObjectMs/School/PageRecord.vue'),
    },
    {
      // 建築物管理
      path: 'BuildingMs',
      name: 'BuildingMs',
      component: () => import('@/views/php/SchoolBuildingObjectMs/BuildingMs/PageRecord.vue'),
    },
    {
      // 學校資料管理-詳細資料
      path: 'InformationManage',
      name: 'InformationManage',
      component: () => import('@/views/php/SchoolBuildingObjectMs/School/InformationManage.vue'),
    },

  ]
};

export default SchoolBuildingObjectMs;
