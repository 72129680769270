<template>
  <fieldwrapper>
    <!--
    <klabel
      :editor-id="id"
      :editor-valid="valid"
    >
      {{ label }}
    </klabel>
    -->
    <div class="k-form-field-wrap">
      <Flabel
        :label="label"
        :editor-id="id"
        :editor-value="value"
        :editor-valid="valid"
        :disabled="disabled"
        :optional="optional"
      >
        <DropDownList
          ref="refOpenDropDown"
          :style="{ width: '100%;' }" 
          :name="name"
          :data-items="data" 
          :valid="valid"
          :value="value"
          :default-value="defaultValue"
          :disabled="disabled"
          :icon="showLockIcon"
          :id="id"
          :text-field="textField"
          :required="required"
          @change="handleChange"
          @blur="handleBlur"
          @focus="handleFocus"
        />
      </Flabel>
      <error v-if="showValidationMessage">
        {{ validationMessage }}
      </error>
      <hint v-else>
        {{ hint }}
      </hint>
    </div>
  </fieldwrapper> 
</template>
<script>
import { FieldWrapper } from '@progress/kendo-vue-form';
import { Error, Hint, Label, FloatingLabel } from '@progress/kendo-vue-labels';
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    fieldwrapper: FieldWrapper,
    error: Error,
    hint: Hint,
    //klabel: Label,
    Flabel: FloatingLabel,
    DropDownList: DropDownList
  },
  props: {
    textField: String,
    data: Object,
    name: String,
    touched: Boolean,
    label: String,
    validationMessage: String,
    hint: String,
    id: String,
    valid: Boolean,
    value: Object,
    defaultValue: Object,
    disabled: Object,
    required: Boolean,
  },
  emits: {
    change: null,
    blur: null,
    focus: null
  },
  computed: {
    showLockIcon(){
      return this.$props.disabled ? 'lock' : '' ; 
    },
    showValidationMessage() {
      return this.$props.touched && this.$props.validationMessage;
    },
    showHint() {
      return !this.showValidationMessage && this.$props.hint;
    },
    hintId() {
      return this.showHint ? `${this.$props.id}_hint` : '';
    },
    errorId() {
      return this.showValidationMessage ? `${this.$props.id}_error` : '';
    }
  },
  mounted() {
    
  },
  methods: {
    handleChange(e){
      this.$emit('change', e);
      this.$emit('onchange', e, this.id);
    },
    handleBlur(e){
      this.$emit('blur', e);
    },
    handleFocus(e){
      this.$emit('focus', e);
    },
    onOpenDropDown(){
      if (this.$refs.refOpenDropDown){
        this.$refs.refOpenDropDown.handleWrapperClick();
      }
    },
    onCloseDropDown(){
      if (this.$refs.refOpenDropDown && this.$refs.refOpenDropDown.currentOpened){
        this.$refs.refOpenDropDown.handleWrapperClick();
        this.$refs.refOpenDropDown.handleBlur();
      }
    },
  }
};
</script>
